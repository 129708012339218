<template>
  <card class="card-user">
    <div slot="image">
      <img src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
           alt="...">
    </div>
    <div>
      <div class="author">
        <img class="avatar border-gray" src="static/img/default-avatar.png" alt="...">
        <h4 class="title">Tania Keatley
          <br>
          <a href="#">
            <small>tania123</small>
          </a>
        </h4>
      </div>
      <p class="description text-center">
        <span>
          "Lamborghini Mercy
          <br>Your chick she so thirsty
          <br>I'm in that two seat Lambo"
        </span>
      </p>
    </div>
    <hr>
    <div class="text-center">
      <div>
        <button type="button" class="btn btn-simple btn-link btn-icon"><i class="fa fa-facebook-square"></i></button>
        <button type="button" class="btn btn-simple btn-link btn-icon"><i class="fa fa-twitter"></i></button>
        <button type="button" class="btn btn-simple btn-link btn-icon"><i class="fa fa-google-plus-square"></i></button>
      </div>
    </div>
  </card>
</template>
<script>
  export default {}
</script>
<style>

</style>
