<template>
  <div>
    <card v-if="spinning">
      <div class="row">
        <div class="col-md-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-sm-4">
          <ValidationProvider
            name="password2"
            rules="required|min:5"
            v-slot="{ passed, failed }"
          >
            <fg-input type="password"
                      :error="failed ? 'The Password field is required': null"
                      :hasSuccess="passed"
                      name="password1"
                      label="Password1"
                      v-model="password1">
            </fg-input>
          </ValidationProvider>
          <ValidationProvider
            name="password1"
            rules="required|min:5"
            v-slot="{ passed, failed }"
          >
            <fg-input type="password"
                      :error="failed ? 'The Password field is required': null"
                      :hasSuccess="passed"
                      name="password2"
                      label="Password2"
                      v-model="password2">
            </fg-input>
          </ValidationProvider>
          <div class="text-center">
            <button type="submit" class="btn btn-fill btn-info btn-round btn-wd" @click="submit">Change</button>
            <br>
          </div>
        </div>
      </div>
    </card>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <span><h5>Error Occurred</h5></span>
      <div class="row">
        <div class="col-md-12"></div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <label>{{ error_text }}</label>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">Error</button>&nbsp;
</span>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span><h5>Success</h5></span>
      <div class="row">
        <div class="col-md-12"></div>
      </div>
      <div class="row text-center">
        <div class="col-md-12">
          <label>{{ success_text }}</label>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
<button class="btn btn-ok" @click="closeModal('success')">Ok</button>&nbsp;
</span>
    </el-dialog>
  </div>
</template>
<script>
// import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
import { extend } from "vee-validate";
import { email, min, required } from "vee-validate/dist/rules";
import UserAPIService from "../../../servicehandlers/UsersAPIService";
import { Dialog, } from 'element-ui';

const userAPIService = new UserAPIService();

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      spinning: false,
      password: null,
      password1: null,
      password2: null,
      error_text: null,
      success_text: null,
      modals: {
        error: false,
        success: false,
      },
    };
  },
  methods: {
    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    submit() {
      this.spinning = true;
      if (this.password1 !== this.password2) {
        this.spinning = false;
        this.error_text = 'Passwords no not match';
        this.openModal('error');
        return;
      } else if (this.password1.length < 8) {
        this.spinning = false;
        this.error_text = 'Password length needs to be at least 8 characters';
        this.openModal('error');
        return;
      } else {
        this.password = this.password1;
      }
      const userId = this.$store.getters.userId;
      const parms = {
        user_id: userId,
        password: this.password,
      };
      return userAPIService.changePassword(parms, this.$router)
      .then(() => {
        this.spinning = false;
        this.success_text = 'Successfully reset password';
        this.password1 = null;
        this.password2 = null;
        this.password = null;
        this.openModal('success');
      })
      .catch((error) => {
        this.spinning = false;
        this.error_text = 'Error resetting password: ' + error;
        this.openModal('error');
      });
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      document.body.classList.remove('off-canvas-sidebar');
    }
  },
  beforeDestroy() {
    this.closeMenu();
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
