import { defineStore } from 'pinia';
export const useLoginStore = defineStore('loginStore', {
  state: () => ({
    username: '',
    name: '',
    userId: 0,
    userScopes: [],
    userGroups: [],
    loggedIn: false,
    sideMenu: false,
  }),
  getters: {
    getUsername(state) {
      return state.username;
    },
    getName(state) {
      return state.name;
    },
    getUserScopes(state) {
      return state.userScopes;
    },
    getUserGroups(state) {
      return state.userGroups;
    },
    getLoggedIn(state) {
      return state.loggedIn;
    }
  },
  actions: {
    setLoggedIn(loggedIn) {
      this.loggedIn = loggedIn;
    },
    setName(name) {
      this.name = name;
    },
    setUsername(name) {
      this.username = name;
    },
    setScopes(scopes) {
      this.userScopes = scopes;
    },
    setGroups(groups) {
      this.userGroups = groups;
    }
  }
})

