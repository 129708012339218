<template>
  <div>
    Main Overview Page
  </div>
</template>
<script>
  // import {ChartCard, StatsCard, Card, Table as LTable, Checkbox} from 'src/components/index'

  export default {
    components: {
    //   Checkbox,
    //   Card,
    //   LTable,
    //   ChartCard,
    //   StatsCard
    },
    data () {
      return {
      };
    }
  }
</script>
<style>

</style>
